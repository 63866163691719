<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-descriptions title="" :column="2" border>
        <el-descriptions-item label="举报人">{{dataInfo.user_name}}</el-descriptions-item>
        <el-descriptions-item label="举报账号">{{dataInfo.user_account}}</el-descriptions-item>
        <el-descriptions-item label="被举报人">{{dataInfo.object_name	}}</el-descriptions-item>
        <el-descriptions-item label="被举报账号">{{dataInfo.object_account}}</el-descriptions-item>
        <el-descriptions-item label="举报时间">{{dataInfo.create_time}}</el-descriptions-item>
        <el-descriptions-item label="举报类型">{{dataInfo.report_type}}</el-descriptions-item>
        <el-descriptions-item label="工单编号">{{dataInfo.order_no}}</el-descriptions-item>
        <el-descriptions-item label="工单标题">{{dataInfo.title}}</el-descriptions-item>
        <!-- curStatus 状态2已处理时展示处理结果 -->
        <template v-if="curStatus === 2">
          <el-descriptions-item label="处理时间">{{dataInfo.update_time}}</el-descriptions-item>
          <el-descriptions-item label="处理结果">{{statusOpt[dataInfo.status]}}</el-descriptions-item>
        </template>
      </el-descriptions>
      <el-form ref="form" :model="form" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="举报说明：">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                :readonly="true"
                v-model="dataInfo.content">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上传凭证：">
              <span v-for="img in dataInfo.image" :key="img" style="margin-right:10px;">
                <el-image
                style="width: 100px; height: 100px"
                :src="img"
                fit="cover"
                :preview-src-list="dataInfo.image"></el-image>
              </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- curStatus 状态1未处理时展示处理按钮 -->
      <el-row type="flex" justify="center" v-if="curStatus === 1">
        <el-button @click='toggle(false)'>返回</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='handleBtn'>处理</el-button>
      </el-row>

      <el-dialog
        :title="form.data.status === 2 ? '选择处理方式' : (form.data.status === 3 ? '发送警告通知' : '发送封号处罚通知')"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="reset"
        append-to-body>
        <el-form
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='110px'
          label-position='top'
          :model='form.data'
          :rules='form.rules'
        >
          <el-form-item prop="status" label="">
            <el-radio-group v-model="form.data.status" @change="handleStatus">
              <el-radio :label="2">不予处理</el-radio>
              <el-radio :label="3">警告</el-radio>
              <el-radio :label="4">封号</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 警告、封号 -->
          <template v-if="form.data.status === 3 || form.data.status === 4">
            <el-form-item prop="date" label="封号期限" v-if="form.data.status === 4">
              <el-row :gutter="10">
                <el-col :span="20" v-if="form.data.is_forever === false">
                  <el-date-picker
                    v-model="form.data.date"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="handleTime">
                  </el-date-picker>
                </el-col>
                <el-col :span="4">
                  <el-checkbox v-model="form.data.is_forever" border @change="handleYj">永久</el-checkbox>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="notify" label="发送警告通知">
              <el-input
                v-model="notify"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 8}"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="object_account" label="被举报账号">
              <el-input
                v-model="dataInfo.object_account"
                :readonly="true"
              >
              </el-input>
            </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </el-dialog>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        image: [],
      },
      form: {
        data: {
          id: '',
          status: 2,
          date: '',
          is_forever: false,
          object_accoun: '',
        },
        rules: {
          date: [{ required: true, message:'请选择封号期限', trigger: 'change' }],
        },
      },
      dialogVisible: false,
      curStatus: '',
      notify: '',
      statusOpt:{
        1:'未处理',
        2:'不予处理',
        3:'警告',
        4:'封号'
      },
    }
  },
  methods: {
    // 获取详情
    getDetail(row, curStatus) {
      this.form.data.id = row.id
      this.curStatus = curStatus
      this.isChange = true
      this.$http.get('/admin/flex_report/info', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    handleRadio(value) {
      console.log(value)
    },
    // 处理
    handleBtn() {
      this.dialogVisible = true;
    },
    // 确定
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {}
          // 2.不予处理, 3.警告, 4封号
          if(this.form.data.status === 2 || this.form.data.status === 3) {
            _params = {
              id: this.form.data.id,
              status: this.form.data.status,
            }
          } else {
            _params ={
              id: this.form.data.id,
              status: this.form.data.status,
              is_forever: this.form.data.is_forever === true ? 1 : 2,
              date: this.form.data.date,
            }
          }
          this.$http.post('admin/flex_report/operation', _params).then(res => {
            if(res.code === 1) {
              this.dialogVisible = false
              this.toggle(false);
              this.$emit('refresh')
              this.$message.success('操作成功!')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    handleYj(val) {
      if(val === true) {
        this.form.data.date = ''
        this.form.rules.date = [{}]
        this.notify = '尊敬的用户您好，经系统审查您因严重违反平台服务规则，存在被多次举报、发布虚假信息、不让线上交易、涉嫌欺诈、发布违规内容等相关平台禁止问题，您的账号已被封禁，封禁时间永久。为创建良好诚信网络交易平台，需诚信使用账号，如有不便敬请谅解。'
      } else {
        this.form.rules.date = [{ required: true, message:'请选择封号期限', trigger: 'change' }]
      }
    },
    handleStatus(status) {
      if(status === 3) {
        this.notify = '尊敬的用户您好，您该条信息被举报不让线上交易，请您遵守平台服务规则，否则系统将会进行封号处理，敬请谅解，谢谢'
      } else if(status === 4) {
        this.notify = `尊敬的用户您好，经系统审查您因严重违反平台服务规则，被多次举报发布虚假信息、不让线上交易、涉嫌欺诈、发布违规内容等，您的账号已被封禁，封禁时间至${this.form.data.date}。为创建良好诚信网络交易平台，请及时整改，如再次出现将会永久封号，敬请谅解，感谢您的配合。`
      }
    },
    handleTime(value) {
      this.notify = `尊敬的用户您好，经系统审查您因严重违反平台服务规则，被多次举报发布虚假信息、不让线上交易、涉嫌欺诈、发布违规内容等，您的账号已被封禁，封禁时间至${value}。为创建良好诚信网络交易平台，请及时整改，如再次出现将会永久封号，敬请谅解，感谢您的配合。`
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;

    .info-top{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }
  
}
.portrait {
  text-align: center;
}
.portrait ::v-deep .el-avatar--large {
  width: 60px;
  height: 60px;
  line-height: 40px;
}
</style>