<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-divider content-position="left">个人信息</el-divider>
      <div class="info-top">
        <div style="text-align:left;">
          <p>工单编号: {{dataInfo.order.order_no}}</p>
          <p>发布者：{{dataInfo.job.name}}</p>
          <p>联系电话：{{dataInfo.job.account}}</p>
        </div>
        <el-avatar
          style="width: 80px; height: 80px;margin-left:50px"
          shape="square"
          :src="dataInfo.job.avatar"
          fit="cover">
          <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
        </el-avatar>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="招工信息" :column="1" border>
        <el-descriptions-item label="发布时间">{{dataInfo.job.create_time}}</el-descriptions-item>
        <el-descriptions-item label="所属工种">{{dataInfo.job.kinds_str}}</el-descriptions-item>
        <el-descriptions-item label="招工标题">{{dataInfo.job.title}}</el-descriptions-item>
        <el-descriptions-item label="工作描述">{{dataInfo.job.content}}</el-descriptions-item>
        <el-descriptions-item label="用工时间">{{dataInfo.job.start_time}} - {{dataInfo.job.start_time}}</el-descriptions-item>
        <el-descriptions-item label="用工地址">{{dataInfo.job.address}}</el-descriptions-item>
        <el-descriptions-item label="结算金额">¥{{dataInfo.job.amount}}&nbsp;&nbsp;{{settleType[dataInfo.settle_type]}}</el-descriptions-item>
        <el-descriptions-item label="浏览量">{{dataInfo.job.browse_count}}次</el-descriptions-item>
        <el-descriptions-item label="报名人数">{{dataInfo.job.apply_num}}人</el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="工单信息" :column="2" border style="margin-top:20px;">
        <el-descriptions-item label="选择工人">{{dataInfo.order.name}}</el-descriptions-item>
        <el-descriptions-item label="性别">{{dataInfo.order.sex === 1 ? '男' : '女'}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{dataInfo.order.phone}}</el-descriptions-item>
        <el-descriptions-item label="报名时间">{{dataInfo.order.apply_time}}</el-descriptions-item>
        <el-descriptions-item label="选择时间">{{dataInfo.order.choose_time}}</el-descriptions-item>
        <el-descriptions-item label="工单金额">¥{{dataInfo.order.total_amount}}&nbsp;&nbsp;{{settleType[dataInfo.job.settle_type]}}</el-descriptions-item>
        <!-- <el-descriptions-item label="服务类型">{{dataInfo.order.service_type === 1 ? '包工包料' : '包工不包料'}}</el-descriptions-item>
        <el-descriptions-item label="预付金额">¥{{dataInfo.order.prepay_amount}}</el-descriptions-item>
        <el-descriptions-item label="尾款金额">¥ {{dataInfo.order.total_amount - dataInfo.order.prepay_amount}}</el-descriptions-item> -->
        <!-- 状态不是待确认时都展示确认时间 -->
        <!-- <template v-if="dataInfo.order.status !== 1">
          <el-descriptions-item label="确认时间">{{dataInfo.order.check_time}}</el-descriptions-item>
        </template> -->
        <!-- 待完工 -->
        <template v-if="dataInfo.order.status === 3">
          <el-descriptions-item label="开工时间">{{dataInfo.order.work_time || ''}}</el-descriptions-item>
        </template>
        <!-- 待结算 -->
        <template v-if="dataInfo.order.status === 4">
          <el-descriptions-item label="开工时间">{{dataInfo.order.work_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="完工时间">{{dataInfo.order.complete_time}}</el-descriptions-item>
        </template>
        <!-- 已完成 -->
        <template v-if="dataInfo.order.status === 5">
          <el-descriptions-item label="开工时间">{{dataInfo.order.work_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="完工时间">{{dataInfo.order.complete_time}}</el-descriptions-item>
          <el-descriptions-item label="结算时间">{{dataInfo.order.settlement_time}}</el-descriptions-item>
        </template>
        <!-- 申请取消中/或申请退款中 -->
        <template v-if="dataInfo.order.cancel_type > 0 && dataInfo.order.status > 4">
          <el-descriptions-item label="申请取消时间">{{dataInfo.order.apply_cancel_time}}</el-descriptions-item>
        </template>
        <!-- 师傅已取消 -->
        <template v-if="dataInfo.order.cancel_type > 0 && dataInfo.order.status < 4 ">
          <el-descriptions-item label="申请取消时间">{{dataInfo.order.apply_cancel_time}}</el-descriptions-item>
          <el-descriptions-item label="对方同意时间">{{dataInfo.order.cancel_time}}</el-descriptions-item>
        </template>

        <el-descriptions-item label="状态">
           <span>{{dataInfo.order.cancel_type ? (dataInfo.order.status > 4 ? cancel[dataInfo.order.cancel_type] : applyCancel[dataInfo.order.cancel_type]) :status[dataInfo.order.status]}}</span>
        </el-descriptions-item>
      </el-descriptions>
       <!-- 待开工、待完工展示开工打卡图片 -->
      <template v-if="dataInfo.order.status === 2 || dataInfo.order.status === 3">
        <el-descriptions title="开工打卡图片" :column="1" :colon="false" style="margin-top:20px">
          <el-descriptions-item label="">
            <el-image
              style="width: 100px; height: 100px"
              :src="dataInfo.order.clock_image"
              fit="cover"
              :preview-src-list="[dataInfo.order.clock_image]">
                 <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <!-- 已完成展示完工打卡图片 -->
      <template v-if="dataInfo.order.status === 5">
        <el-descriptions title="完工打卡图片" :column="1" :colon="false" style="margin-top:20px">
          <el-descriptions-item label="">
            <span v-for="img in dataInfo.order.complete_image" :key="img" style="margin-right:10px;">
              <el-image
                style="width: 100px; height: 100px"
                :src="img"
                fit="cover"
                :preview-src-list="dataInfo.order.complete_image"></el-image>
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        job: {},
        order: {}
      },
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      status:{
        0:'待确认',
        1:'待确认',
        2:'待开工',
        3:'待完工',
        4:'待结算',
        5:'已完成'
      },
      applyCancel:{
        1:"申请退款",
        2:"申请取消"
      },
      cancel:{
        1:"老板已经取消",
        2:"师傅已经取消"
      }
    }
  },
  methods: {
    // 获取工单详情
    getDetail(row) {
      this.isChange = true
      this.$http.get('/admin/flex_order/info', {params:{id: row.order_id} }).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    .info-top{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }
}
</style>